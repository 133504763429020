export const environment = {
  production: false,

  // ########### Urls di Test ###########
  itemsUrl: "https://ecommerce-be.digitalbusinessolution.com/gateway/articoli",
  accessesUrl: "https://ecommerce-be.digitalbusinessolution.com/gateway/utenti",
  filesUrl: "https://ecommerce-be.digitalbusinessolution.com/gateway/allegati",
  purchasesUrl: "https://ecommerce-be.digitalbusinessolution.com/gateway/acquisti",
  addressesUrl: "https://ecommerce-be.digitalbusinessolution.com/gateway/indirizzi",
  reviewsUrl: "https://ecommerce-be.digitalbusinessolution.com/gateway/recensioni",
};
